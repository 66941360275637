import React, { useState, useEffect } from "react";
import Link from "gatsby-link"
import Layout from "../components/layout"
import { Container, Row, Col } from "reactstrap";
import SEO from "../components/common/SEO";
import Hero from "../components/common/Hero/PageHero";
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-deferred';
import 'chartjs-plugin-datalabels';
import CTABlock from "../components/cta-block";
import {
  isSafari
} from "react-device-detect";

const AttendeeData = {
	labels: [
		'Exhibitor',
    'Customer',
    'Partner',
    'Consultant',
    'Distributor',
    'Student',
    'Employee'
	],
	datasets: [{
		data: [1, 46, 3, 6, 12, 3, 29],
		backgroundColor: [
    '#404040',
    '#0085AD',
		'#008C95',
    '#FFAB18',
    '#62D13C',
    '#615E9B',
    '#E57200'
		],
  }]
};

const AttendeeOptions = {
  layout: {
    padding: {
        top: 30,
        bottom: 30,
    }
  },
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        //get the concerned dataset
        var dataset = data.datasets[tooltipItem.datasetIndex];
        //calculate the total of this data set
        var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
          return previousValue + currentValue;
        });
        //get the current items value
        var currentValue = dataset.data[tooltipItem.index];
        //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
        var percentage = ((currentValue/total) * 100).toFixed(0) + "%";

        var label = data.labels[tooltipItem.datasetIndex].label || '';

        if (label) {
            label += ': ';
        }
        label += percentage
  
        return label;
      }
    }
  },
  rotation: (1.2 * Math.PI),
  plugins: {
    deferred: {
      xOffset: 150,   // defer until 150px of the canvas width are inside the viewport
      yOffset: '50%', // defer until 50% of the canvas height are inside the viewport
      delay: 500      // delay of 500 ms after the canvas is considered inside the viewport
    },
    legend: false,
    datalabels: {
      anchor: 'end',
      align: 'end',
      backgroundColor: function(context) {
        return context.dataset.backgroundColor;
      },
      borderColor: 'white',
      borderRadius: 4,
      borderWidth: 2,
      color: 'white',
      font: {
        weight: 'bold',
        size: 16
      },
      textAlign: 'center',
      formatter: (value, ctx) => {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.map(data => {
            sum += data;
        });
        let percentage = (value * 100 / sum) +"%";
        let formattedLabel = ctx.chart.data.labels[ctx.dataIndex];
        return formattedLabel;
      },
    }
  }
}

const IndustryData = {
	labels: [
		'Water',
		'Gas',
    'Electric',
    'Combo',
    'Other'
	],
	datasets: [{
		data: [46.4, 6.7, 18.4, 27.2, 1.3],
		backgroundColor: [
		'#0085AD',
		'#615E9B',
    '#E57200',
    '#FFAB18',
    '#62D13C'
		],
  }],
};

const IndustryOptions = {
  legend: {
    display: false
  },
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        //get the concerned dataset
        var dataset = data.datasets[tooltipItem.datasetIndex];
        //calculate the total of this data set
        var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
          return previousValue + currentValue;
        });
        //get the current items value
        var currentValue = dataset.data[tooltipItem.index];
        //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
        var percentage = ((currentValue/total) * 100).toFixed(1);
  
        return percentage + "%";
      }
    }
  },
  scales: {
    xAxes: [{
        gridLines: {
            display:false
        },
        ticks: {
          fontSize: 16
        }
    }],
    yAxes: [{
        gridLines: {
            display:false
        },
        ticks: {
          display: false, //this will remove only the label
        }
    }]
  },
  plugins: {
    deferred: {
      xOffset: 150,   // defer until 150px of the canvas width are inside the viewport
      yOffset: '50%', // defer until 50% of the canvas height are inside the viewport
      delay: 500      // delay of 500 ms after the canvas is considered inside the viewport
    },
    datalabels: {
      anchor: 'end',
      backgroundColor: function(context) {
        return context.dataset.backgroundColor;
      },
      borderColor: 'white',
      borderRadius: 4,
      borderWidth: 2,
      color: 'white',
      font: {
        weight: 'bold',
        size: 16
      },
      formatter: (value, ctx) => {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.map(data => {
            sum += data;
        });
        let percentage = (value*100 / sum).toFixed(1)+"%";
        return percentage;
      },
    }
  }
}
 

const ReachRecap = () => {
    const [safari, setSafari] = useState(false);

    useEffect(() => {
      setSafari(isSafari);
    })

    return (
        <Layout pageId="reach-recap">
        <SEO title="Reach Recap" />
        <Hero title="Reach Recap" />
          <section className="content">
            <Container>
              <Row>
                <Col xs={12}>
                  <h2>The Digital Experience</h2>
                  <hr />
                  <p>In 2020, we transformed our annual event into an inclusive digital experience to connect with our customers, colleagues, distributors and partners across the globe.</p>
                </Col>
              </Row>
            </Container>
            <div className="content-section first">
              <Container>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="number">104</div>
                    <p className="h3">Sessions</p>
                    <p>+ bonus content</p>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className={safari ? "number safari" : "number"}>
                      <span className="odometer-digit">
                        5
                      </span>
                      <span className="odometer-digit">
                        1
                      </span> 
                      <span className="odometer-digit">
                        7
                      </span> 
                      <span className="odometer-digit moving">
                        <span className="odometer-moving">
                          4
                        </span>
                        <span className="odometer-moving">
                          5
                        </span>
                      </span>
                    </div>
                    <p className="h3">Hours watched</p>
                    <p>...and counting!</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CTABlock ctaId="digital" page="recap" />
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="content-section second">
              <Container>
                <Row>
                  <Col lg={6} xs={12}>
                    <img src="/images/Recap_People_Computer.svg" alt="Digital Conference Users" />
                  </Col>
                  <Col lg={{ size: 5, offset: 1 }} xs={12} className="registrants-attendees">
                    <p className="h3">2,326+</p>
                    <p>Registrants</p>
                    <hr />
                    <p className="h3">1,865</p>
                    <p>Attendees</p>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="content-section third">
              <Container>
                <Row>
                  <Col xs={12}>
                    <h3 className="h2 mb-5">Exploring new opportunities within reach</h3>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col lg={{ size: 4, offset: 1 }} xs={12} className="chart-text">
                    <h4>Opportunities for All Utilities</h4>
                    <p>With over 100 digital sessions and 2300+ registrants, Reach 20 included the full range of utilities from across the world with three common goals: <strong>Connect</strong>, <strong>Learn</strong>, and <strong>Grow</strong>.</p>
                  </Col>
                  <Col lg={{ size: 6, offset: 1 }} xs={12}>
                    <Bar data={IndustryData} options={IndustryOptions} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={{ size: 8 }} xs={12}>
                    <Doughnut data={AttendeeData} options={AttendeeOptions} />
                  </Col>
                  <Col lg={{ size: 4 }} xs={12} className="chart-text">
                    <h4>Reaching a Diverse Audience</h4>
                    <p>Reach 20 featured content that engaged attendees ranging from Xylem customers to inspired students.</p>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="content-section fourth">
              <Container>
                <Row>
                  <Col xs={12}>
                    <h3>Xylem Ignite Hackathon</h3>  
                  </Col>
                  <Col lg={3} md={6} xs={12}>
                    <p className="number">12</p>
                    <p>Professional Teams</p>
                  </Col>
                  <Col lg={3} md={6} xs={12}>
                    <p className="number">75+</p>
                    <p>Xylem Software Engineers</p>
                  </Col>
                  <Col lg={3} md={6} xs={12}>
                    <p className="number">100+</p>
                    <p>Student Registrations</p>
                  </Col>
                  <Col lg={3} md={6} xs={12}>
                    <p className="number">9</p>
                    <p>Countries Represented</p>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="content-section fifth">
              <Container>
                <Row>
                  <Col lg={{ size: 5 }} xs={12}>
                    <img src="/images/Recap_Testimonials.png" alt="Digital Conference Testimonials" />
                  </Col>
                  <Col lg={{ size: 1 }} xs={12} className="border-right">
                  </Col>
                  <Col lg={{ size: 1 }} xs={12}>
                  </Col>
                  <Col lg={{ size: 5}} xs={12}>
                    <img src="/images/Recap_Watermark.png" alt="Digital Conference Testimonials" />
                    <p>Reach attendees participated in our Xylem Watermark effort and helped raise money to be donated to the Mercy Corps organization.</p>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="content-section sixth">
              <Container>
                <Row>
                  <Col xs={12}>
                    <h3>Interested in the hybrid Reach21 event?</h3>
                    {
                      // TODO
                    }
                    <p>We'll keep you up to date with details about how to register for Reach 2021 in New Orleans!</p>
                    <a href="https://cvent.me/Yg3nod?RefId=Reach+2021+Registration" className="primary-cta">Register Now</a>
                    <img src="/images/Recap_NOLA_Cityscape.png" alt="Digital Conference Testimonials" />
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
        </Layout>
    )
}

export default ReachRecap;